.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height:100vh;
  font-family: SourceCodePro-Regular;
  font-size: 27.04px;
  letter-spacing: 1.93px;
  font-weight: 400;
  .screen{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-right: 3%;
    padding-left: 3%;
  
    &.screen1{
      background:url('') no-repeat center center;
      background-size:816px;
      .theme-btn{
        position: absolute;
        top:50px;
        right: 80px;
        width: 50px;
        height:50px;
        z-index: 10;
        background:url('') no-repeat center center;
        background-size:cover;
        cursor: pointer;
      }
      .arrow-btn{
        position: absolute;
        bottom:50px;
        left: 50%;
        width: 50px;
        height:50px;
        transform: translateX(-50%);
        z-index: 10;
        background:url('') no-repeat center center;
        background-size:cover;
        animation: slideDown 1.2s ease-in-out infinite;
        cursor: pointer;
      }
      .logo{
        width:268px;
      }
      .command{
        margin-top:24px;
        font-family: SourceCodePro-Regular;
        font-size: 27.04px;
        letter-spacing: 1.93px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        .cursor{
          margin-right: 16px;
          font-size: 21.97px;
          letter-spacing: 1.57px;
          animation: 1s cursor-flicker infinite;
        }
      }
    }
    &.screen2{
      audio{
        display: none;
      }
      .infancy{
        font-family: SourceCodePro-Regular;
        font-size: 22px;
        letter-spacing: 1.57px;
        font-weight: 400;
      }
      .noun{
        margin-top:12px;
        font-family: SourceCodePro-Regular;
        font-size: 18px;
        color: #8E75F1;
        letter-spacing: 1.29px;
        line-height: 24px;
        font-weight: 400;
      }
      .pronounce{
        margin-top:100px;
        padding-left:40px;
        font-family: SourceCodePro-Regular;
        font-size: 16px;
        letter-spacing: 1.14px;
        line-height: 24px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .trumpet{
          margin-right:16px;
          width:24px;
          height: 24px;
          position: absolute;
          top:0;
          left:0;
          z-index:3;
          background:url('../../assets/images/home/trumpet.png') no-repeat center center;
          background-size:cover;
          cursor: pointer;
        }
      }
      .intro{
        margin-top:16px;
        font-family: SourceCodePro-Regular;
        font-size: 22px;
        letter-spacing: 1.57px;
        font-weight: 400;
      }
      .join-btn{
        margin-top:100px;
        width:255px;
        height:50px;
        background: rgba(112,159,255,0.15);
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: SourceCodePro-Regular;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
        .icon-tg{
          margin-right:16px;
          width:20px;
          height: 20px;
          background:url('../../assets/images/home/ic_tg.png') no-repeat center center;
          background-size:cover;
        }
        &:hover{
          background: #4F7AD2;
          .icon-tg{
            background-image:url('../../assets/images/home/ic_tg_hover.png');
          }
        }
      }
      .media-list{
        position: absolute;
        bottom:50px;
        left: 0;
        width:100%;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        .media-item{
          display: block;
          width: 20px;
          height: 20px;
          &.twitter{
            background:url('') no-repeat center center;
            background-size:cover;
          }
        }
      }
    }
  }
  &.dark{
    color: #FFFFFF;
    background-color: #000;
    .screen1{
      background-image:url('../../assets/images/home/bg2.png');
      .theme-btn{
        background-image:url('../../assets/images/home/theme_light.png');
      }
      .arrow-btn{
        background-image:url('../../assets/images/home/arrow_bottom_white.png');
      }
    }
    .screen2{
      .command{
        color: rgba(255,255,255,0.5);
      }
      .pronounce{
        color: rgba(255,255,255,0.3);
        .phonetic-symbols{
          color: rgba(255,255,255,0.7);
        }
      }
      .join-btn:hover{
        background: #4F7AD2;
      }
      .media-item{
        &.twitter{
          background-image:url('../../assets/images/home/twitter_white.png')!important;
        }
      }
    }
  }
  &.light{
    color: #121212;
    background-color: #FFF;
    .screen1{
      background-image:url('../../assets/images/home/bg1.png');
      .theme-btn{
        background-image:url('../../assets/images/home/theme_dark.png');
      }
      .arrow-btn{
        background-image:url('../../assets/images/home/arrow_bottom_black.png');
      }
    }
    .screen2{
      .command{
        color: rgba(0,0,0,0.5);
      }
      .pronounce{
        color: rgba(0,0,0,0.3);
        .phonetic-symbols{
          color: rgba(0,0,0,0.7);
        }
      }
      .join-btn:hover{
        background: #4F7AD2;
      }
      .media-item{
        &.twitter{
          background-image:url('../../assets/images/home/twitter_black.png')!important;
        }
      }
    }
  }
}

@keyframes scrollY {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes cursor-flicker {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slideDown {
  0% {
    bottom: 50px; 
    opacity: 0.8;
  }
  50% {
    bottom: 30px; 
    opacity: 1;
  }
  100% {
    bottom: 50px;
    opacity: 0.8;
  }
}

@media screen and (max-width: 800px) {
  .screen{
    &.screen1 {
      background-size:100%!important;
      .theme-btn{
        top: 30px!important;
        right: 30px!important;
      }
      .arrow-btn{
        bottom:calc(50px + constant(safe-area-inset-bottom) + constant(safe-area-inset-top))!important;
        bottom:calc(50px + env(safe-area-inset-bottom) + env(safe-area-inset-top))!important;
      }
      .command {
        font-size: 14px!important;
        .cursor{
          font-size: 12px!important;
        }
      }
    }
    &.screen2 {
      padding-bottom: 33%;
      .pronounce, .join-btn{
        margin-top: 60px!important;
      }
      .intro {
        font-size: 14px!important;
      }
      .media-list{
        bottom:calc(50px + constant(safe-area-inset-bottom) + constant(safe-area-inset-top))!important;
        bottom:calc(50px + env(safe-area-inset-bottom) + env(safe-area-inset-top))!important;
      }
    }
  }
}
